import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi,`}</h1>
    <p>{`I'm mr sound. I'm attempting to achieving Financial independence.
To help myself and perhaps others I'm recording my journey in this blog.
For some more information about me and my blog check the about page.
If you have any suggestions or questions let me know on `}<a parentName="p" {...{
        "href": "mailto:sound@soundsthesameas.com"
      }}>{`sound@soundsthesameas.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      